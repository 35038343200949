import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/ecosystem-partners';

const EcosystemPartnersRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../EcosystemPartners')),
  name: 'EcosystemPartners',
};

export default EcosystemPartnersRoute;
