// import { IRouteProps } from 'modules';
// import { lazy } from 'react';
// export const route = '/profile';

// const ProfileRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('./Profile')),
//   name: 'Profile',
// };

// export default ProfileRoute;

export {};
