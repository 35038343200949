// import { lazy } from 'react';
// import { IRouteProps } from '../index';

// export const route = '/launchpad';

// const LiquidityRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../LaunchPad')),
//   name: 'LaunchPad',
// };

// export default LiquidityRoute;
export {};
