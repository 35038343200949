import styled from 'styled-components';
import dropBG from 'assets/bg__dropdown.png';

export const Wrapper = styled.div`
  font-family: 'Hepta Slab';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  width: 100%;
  color: white;
  z-index: 101;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgb(96, 96, 96);
  backdrop-filter: blur(50px);
  transition: all 0.3s;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px;
    ${(props) => props.theme.mediaWidth.upToExtraSmall`
        height: 65px;
      `}
    .menu {
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      border: 1px solid #666666;
      border-radius: 2px;
      display: none;
      ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: flex;
      `}

      span {
        font-family: 'Hepta Slab';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #ffffff;
      }
    }
    .left-header {
      display: flex;
      align-items: center;
      gap: 40px;
      .nav-lists {
        display: flex;
        align-items: center;
        gap: 24px;
        ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: none;
      `}
        .nav-nav {
          cursor: pointer;
          font-family: 'Hepta Slab';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          /* identical to box height, or 26px */

          color: #ffffff;
          transition: all 0.3s;
          // padding: 8px 22px;
          // border-radius: 8px;
          &.hide-mobile {
            ${(props) => props.theme.mediaWidth.upToMedium`
        display: none;
        
      `}
          }
          &:hover {
          }
          &:active {
            transform: scale(0.85);
            opacity: 0.5;
          }
        }
      }
    }
    .btn-launch-gt {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 20px 24px;
      border: 1px solid #ffffff;
      border-radius: 2px;
      cursor: pointer;
      ${(props) => props.theme.mediaWidth.upToExtraSmall`
         display: none;
      `}
      img {
        width: 24px;
      }
      span {
        font-family: 'Hepta Slab';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        color: #ffffff;
      }
    }
    .logo {
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .account-wrapper {
      ${(props) => props.theme.mediaWidth.upToMedium`
         display: none;
      `}
    }
    .account-info {
      cursor: pointer;
      background-color: rgb(42, 42, 45);
      box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
      padding: 8px 22px;
      display: flex;
      align-items: center;
      border-radius: 24px;
      gap: 12px;
      position: relative;

      .account-hover {
        bottom: 0;
        position: absolute;

        flex-direction: column;
        align-items: center;
        left: 50%;
        bottom: 0;
        transform: translateY(100%) translateX(-50%);
        background: url(${dropBG});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        min-width: 210px;
        color: #2c1b05;
        display: none;
        > div {
          font-size: 15px;
          text-align: center;
          padding: 15px 0px;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            color: #dcb85c;
          }
        }
        /* height: 1000px; */
        /* bottom: 0;
        left: 50%;
        transform: translateY(100%) translateX(-50%); */
      }
      &:hover {
        .account-hover {
          display: flex;
        }
      }
      .left {
        display: flex;
        gap: 8px;
        align-items: center;
        img {
          width: 20px;
        }
      }
      .account-address {
      }
    }
  }
`;
