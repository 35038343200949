// import { lazy } from 'react';
// import { IRouteProps } from '../index';

// export const route = '/liquidity-partners';

// const LiquidityPartnerRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('../LiquidityPartner')),
//   name: 'LiquidityPartner',
// };

// export default LiquidityPartnerRoute;
export {};
