import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/leaderboard';

const LeaderBoardRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../LeaderBoard')),
  name: 'LeaderBoard',
};

export default LeaderBoardRoute;
