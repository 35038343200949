import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/';

const SwapRoute: IRouteProps = {
  path: route,
  exact: true,

  component: lazy(() => import('../Swap')),
  name: 'Swap',
};

export default SwapRoute;
