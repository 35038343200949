import { lazy } from 'react';
import { IRouteProps } from '../index';

export const route = '/liquidity/:pair';

const LiquidityDetailRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import('../LiquidityDetail')),
  name: 'LiquidityDetail',
};

export default LiquidityDetailRoute;
